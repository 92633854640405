import { type CartItem } from '@tn/shared';
import {
  useAddToCartMutation,
  useRemoveFromCartMutatation,
} from '../mutations';
import { useUserCartQuery } from '../queries';
import { useOrderData } from '~/features/orders';

export function useCart() {
  const { uid } = useUserState();
  const {
    data: cart,
    isLoading,
    suspense,
    error,
  } = useUserCartQuery(uid.value);
  const { canOrderProduct } = useOrderData();
  const { mutateAsync: addToCartMutation } = useAddToCartMutation();
  const { mutateAsync: removeFromCartMutation } = useRemoveFromCartMutatation();

  const products = computed(() => cart.value || []);

  const isInCart = (productId: string) => {
    return products.value.some((cartItem) => cartItem.productId === productId);
  };

  const isEmptyCart = computed(() => products.value.length === 0);

  const cartItemsCount = computed(() => {
    return products.value.reduce((acc, item) => acc + item.quantity, 0);
  });

  const totalCost = computed(() => products.value.length);

  const addProductToCart = async (product: CartItem) => {
    if (isInCart(product.productId) || !canOrderProduct(product.productId)) {
      return {
        success: false,
        message: 'Duplicate samples cannot be ordered',
      };
    }

    try {
      await addToCartMutation(product);
      return {
        success: true,
        message: 'Great choice! Item added to cart',
      };
    } catch (err) {
      console.error(err);
      return {
        success: false,
        message: 'Failed to add item to cart. Please try again.',
      };
    }
  };

  const removeProductFromCart = async (productId: string) => {
    return await removeFromCartMutation(productId);
  };

  return {
    products,
    isLoading,
    error,
    suspense,
    isInCart,
    isEmptyCart,
    totalCost,
    cartItemsCount,
    addProductToCart,
    removeProductFromCart,
  };
}
