import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { addItemToCart } from '../api';
import { type CartItem } from '@tn/shared';

export const useAddToCartMutation = () => {
  const { uid } = useUserState();
  const queryClient = useQueryClient();
  const queryKey = ['users', uid.value, 'cart'];

  return useMutation({
    mutationFn: async (body: CartItem) => {
      if (!uid.value) {
        return;
      }

      return await addItemToCart(uid.value, body);
    },
    onMutate: async (item) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({ queryKey });

      // Snapshot the previous value
      const previousCart = queryClient.getQueryData(queryKey);

      // Optimistically update to the new value
      queryClient.setQueryData(queryKey, (old: CartItem[]) => [...old, item]);

      // Return a context object with the snapshotted value
      return { previousCart };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newCart, context) => {
      queryClient.setQueryData(queryKey, context?.previousCart ?? []);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });
};
