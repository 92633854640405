import { useUserReviews } from '~/features/reviews';
import { useOrders } from './useOrders';

export function useOrderData() {
  const { orders } = useOrders();
  const { reviews } = useUserReviews();

  const totalOrders = computed(() => orders.value?.length ?? 0);

  const hasOrderedProduct = (productId: string) => {
    if (!totalOrders.value) {
      return false;
    }

    return (orders.value || []).some((order) => !!order.products?.[productId]);
  };

  const canOrderProduct = (productId: string) => {
    if (!totalOrders.value) {
      return true;
    }

    const productOrders = orders.value
      ?.filter((order) => order.products[productId] !== undefined)
      .sort((a, b) => b.createdAtTimeMs - a.createdAtTimeMs);

    const latestOrder = productOrders?.[0];

    if (!latestOrder) {
      return true;
    }

    const reportedReviewForOrder = reviews.value.find(
      (review) =>
        review.orderId === latestOrder.id &&
        review.survey.isReport &&
        review.completedAtTimeMs
    );

    return !!reportedReviewForOrder;
  };

  return {
    hasOrderedProduct,
    canOrderProduct,
  };
}
